export const setActualFooterHeight = {
  data() {
    return {
      footerHeight: 0
    };
  },

  mounted() {
    this.setActualFooterHeight();
  },

  updated() {
    this.setActualFooterHeight();
  },

  methods: {
    setActualFooterHeight() {
      if (this.$refs.footer) {
        this.footerHeight = this.$refs.footer.clientHeight + 20; // + 20 to increase padding
      }
    }
  }
};
